<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";

export default {
  name: "SysTelephonyTrunksIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("trunks", {
      contents: state => state.trunks,
      isLoading: state => state.isLoading,
      SBC_PCI_PRIMARY_INTERNAL_IP_PORT: state =>
        state.SBC_PCI_PRIMARY_INTERNAL_IP_PORT,
      SBC_PCI_SECONDARY_INTERNAL_IP_PORT: state =>
        state.SBC_PCI_SECONDARY_INTERNAL_IP_PORT,
      SBC_PCI_PRIMARY_EXTERNAL_IP_PORT: state =>
        state.SBC_PCI_PRIMARY_EXTERNAL_IP_PORT,
      SBC_PCI_SECONDARY_EXTERNAL_IP_PORT: state =>
        state.SBC_PCI_SECONDARY_EXTERNAL_IP_PORT
    }),

    ...mapState("serviceproviders", {
      serviceProviders: state => state.serviceProviders
    })
  },

  methods: {
    ...mapActions("trunks", {
      getSBCIps: "getSBCIps",
      getAllTrunks: "getAllTrunks"
    }),

    ...mapActions("serviceproviders", {
      fetchTrunksForServiceProvider: "fetchTrunksForServiceProvider",
      getServiceProviders: "getServiceProviders"
    }),

    initContent() {
      return {
        trunk_name: "",
        sip_domain: "",
        sp_name: "",
        registration_type: "",
        pci_compliance: true,
        primary_registrant: {
          registrant_type: "primary",
          register_user_name: "",
          is_pilot_number_overridden: false,
          overridden_pilot_number: "",
          outbound_proxy_ip_port: "",
          sbc_internal_ip_port: this.SBC_PCI_PRIMARY_INTERNAL_IP_PORT,
          sbc_external_ip_port: this.SBC_PCI_PRIMARY_EXTERNAL_IP_PORT,
          ip_whitelist: "",
          dynamic_registrant_user_name: "",
          dynamic_registrant_user_password: "",
          registration_status: ""
        },
        secondary_registrant: {
          registrant_type: "secondary",
          register_user_name: "",
          is_pilot_number_overridden: false,
          overridden_pilot_number: "",
          outbound_proxy_ip_port: "",
          sbc_internal_ip_port: this.SBC_PCI_SECONDARY_INTERNAL_IP_PORT,
          sbc_external_ip_port: this.SBC_PCI_SECONDARY_EXTERNAL_IP_PORT,
          ip_whitelist: "",
          dynamic_registrant_user_name: "",
          dynamic_registrant_user_password: "",
          registration_status: ""
        }
      };
    }
  },

  mounted() {
    // this.fetchAccountsForServiceProvider();
    this.getServiceProviders();
    this.getAllTrunks();
    this.scope = "sys";
    this.contentType = "Trunk";
    this.primaryKey = "trunk_id";
    this.getSBCIps();
  }
};
</script>

<style lang="scss" scoped></style>
